header {
  .top-nav {
    box-shadow: none;
    line-height: 0;

    .left {
      float: none !important;
      margin-right: 56px;

      @media (max-width: $small-screen) {
        margin-right: 0;
      }
    }

    .user-name {
      padding: (($dropdown-item-height - 22) / 2) 16px;
      white-space: normal;
      max-width: 90vw;
    }

    #toggle-menu {
      svg {
        margin: 16px 0;

        @media (max-width: $small-screen) {
          margin: 20px 0;
        }

        path {
          fill: #000;
        }
      }
    }

    .menu-toggle {
      background-color: $primary-color;
      position: absolute;
      width: 237px;
      z-index: 2;

      a:hover {
        background-color: transparent;
      }
    }

    .header-button,
    .button-collapse {
      float: left;
      margin: 0 $gutter-width;
      display: block;
    }

    .dropdown-trigger {
      float: right;
      line-height: $header-height/2;
      position: relative;
      top: $header-height/4;
      margin-left: 1rem;
      z-index: 999;

      @media (max-width: $small-screen) {
        top: $header-height/3;
      }
    }

    .dropdown-content {
      left: auto !important;
      right: 0;
      white-space: nowrap;
    }

    .logo {
      padding: 0;

      img {
        max-height: $navbar-height;
        max-width: 112px;
        width: auto;
        margin: 9px 0;
        padding: 4px;
        object-fit: cover;

        @media (max-width: $small-screen) {
          height: 30px;
          margin: 17px 0;
        }
      }
    }

    .right {
      padding-right: 15px;
    }
  }

  .sidenav {
    width: 232px;
    padding: 12px 8px 52px;
    position: absolute;
    top: $navbar-height-mobile + $subheader-height;
    height: 100vh;
    transition: transform 0.1s;

    @media (min-width: $small-screen) {
      top: $header-height + $subheader-height;
    }

    @media (min-width: $medium-screen) {
      background: transparent;
      box-shadow: none;
    }

    @media (min-width: $large-screen) {
      transform: translateX(0);
    }

    a {
      padding: 0 $sidenav-padding;
    }

    &-section {
      p {
        @extend .grey-text;
        margin: 0;
        height: 40px;
        line-height: 40px;
        padding: 0 $sidenav-padding;
        font-size: 13px;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid map-get($grey, lighten-2);
      }

      padding-top: $gutter-width / 2;

      &#first {
        border-top: 1px solid map-get($grey, lighten-2);
      }
    }

    li {
      border-radius: 2px;

      a {
        font-weight: normal;

        @media (min-width: $medium-screen) {
          height: 40px;
          line-height: 40px;
        }
      }

      &.active {
        a {
          @extend .grey-text;
          @extend .text-darken-4;
          font-weight: bold;
        }
      }

      .body-home &.nav-home {
        @extend .active;
      }
    }
  }
}

.subheader {
  background-color: $primary-color-light;
  height: $subheader-height;
  margin-top: 0;
  position: relative;

  .container {
    width: 100%;
    max-width: none;
    height: $subheader-height;

    .breadcrumbs {
      transition: margin 0.1s;
      padding-left: 15px;
    }

    .dropdown-trigger {
      padding: 11px 15px 0 0;
      line-height: 0;
      max-height: $subheader-height;
    }
  }
}

.main {
  .container {
    margin: 40px 40px 0;
    width: auto;
    max-width: none;
    transition: margin 0.1s;

    @media (max-width: $small-screen) {
      margin: 15px 15px 0;
    }

    @media (min-width: $large-screen) {
      margin-left: 270px;
    }
  }
}

body.nav-toggled {
  header {
    .sidenav {
      transform: translateX(0);

      @media (min-width: $large-screen) {
        transform: translateX(-105%);
      }
    }
  }

  .subheader {
    .container {
      margin-left: 0;
    }
  }

  .main {
    .container {
      @media (min-width: $medium-screen) {
        margin-left: 270px;
      }

      @media (min-width: $large-screen) {
        margin: 40px 40px 0;
      }
    }
  }

  .btn-fab-fixed {
    right: 25%;
  }
}

body.sticky-nav {
  header {
    .sidenav {
      position: fixed;
      top: 0;
    }
  }
}
