.modal-content {
  & > .row {
    margin: {
      left: -0.75rem;
      right: -0.75rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .address {
    margin: 1rem 0;
  }

  blockquote {
    margin: 8px 0;
  }
}

#base-modal {
  @media only screen and (min-width: 1367px) {
    width: 40%;
  }
}

#modal-product-class {
  max-width: 300px;
}

#scan-barcode-dialog {
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    height: 100%;

    h2 {
      position: absolute;
      top: 1rem;
      text-align: center;
      background: white;
      padding: 1rem;
      font-weight: 700;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    }

    .scan-barcode-output {
      position: absolute;
      bottom: 1rem;
      background: white;
      padding: 0.5rem 1rem;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

      &__outputMessage {
        font-weight: bold;
        color: black;
      }
    }

    canvas {
      height: 100%;
    }
  }
}
