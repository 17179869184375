/*** GLOBAL ***/

$roboto-font-path: '~materialize-css/dist/fonts/roboto/';

$header-height: 56px;
$navbar-height: 56px;
$navbar-height-mobile: 64px;
$subheader-height: 48px;
$font-size-action-icon: 18px;
$transition-duration: 0.2s;
$global-padding: 1rem;
$global-margin: 1rem;

/*** COLORS ***/

$off-black: rgba(0, 0, 0, 0.87);
$primary-color: #fadd00;
$secondary-color: #000000;
$success-color: #4caf50;
$error-color: #f44336;
$link-color: #039be5;
$light-gray: #f1f5f5;
$grey-color: #9e9e9e;
$white: #fff;
$black: #000;
$sidenav-font-color: #616161;
$placeholder-text-color: lighten($off-black, 75%);
$body-bg: #f5f5f5;

/*** PADDINGS ***/

$button-padding: 0 16px;
$card-padding: 16px;

/***  Media Query Ranges ***/

$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

/*** Header Styles ***/

$h1-fontsize: 3rem !default;
$h2-fontsize: 2rem !default;
$h3-fontsize: 1.5rem !default;
$h4-fontsize: 1.3rem !default;
$h5-fontsize: 1.2rem !default;
$h6-fontsize: 1rem !default;

/*** Timeline Configuration ***/

$timeline-width: 3px !default;
$timeline-circle-radius: 6px !default;
$timeline-event-margin: 1rem !default;
$timeline-date-width: 20rem;

/*** Medium Editor ***/
$medium-editor-bgcolor: $off-black;
$medium-editor-button-size: 40px;
$medium-editor-button-active-text-color: #fff;
$medium-editor-hover-color: $link-color;
$medium-editor-link-color: #fff;
$medium-editor-border-radius: 2px;
$medium-editor-placeholder-color: $placeholder-text-color;
