@mixin select2 {
  margin-bottom: 20px;
  &.select2-container--default,
  &.select2-container--default.select2-container--focus {
    border-bottom: $input-border;
    box-sizing: border-box;
    .select2-results__options {
      background-color: $dropdown-bg-color;
      .select2-results__option {
        clear: both;
        color: $dropdown-color;
        cursor: pointer;
        font-size: $input-font-size;
        line-height: 22px;
        min-height: $dropdown-item-height;
        padding: (($dropdown-item-height - 22) / 2) 16px;
        text-align: left;
        text-transform: none;
        width: 100%;
      }
      .select2-results__message {
        color: $input-disabled-color;
      }
      .select2-results__option[aria-selected='true'] {
        background-color: darken($dropdown-hover-bg-color, 7%);
      }
      .select2-results__option--highlighted[aria-selected] {
        background-color: $dropdown-hover-bg-color;
      }
    }
    .select2-selection--multiple {
      background: transparent;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      .select2-selection__rendered {
        padding: 0;
        vertical-align: middle;
      }
      li.select2-selection__choice {
        @extend .chip;
        border: 0;
        margin-top: 3px;
        margin-bottom: 4px;
        .select2-selection__choice__remove {
          color: inherit;
          float: right;
          font-size: 0;
          height: 32px;
          margin: 0;
          &:before {
            content: '\00d7';
            font-size: 15px;
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .select2-search--inline {
      .select2-search__field {
        background: transparent;
        height: auto;
        line-height: 32px;
        margin: 3px 0 4px;
        padding-left: 0;
        &:focus {
          border-bottom: none;
          box-shadow: none;
        }
      }
    }
    .select2-selection--single {
      background: transparent;
      border: none;
      height: 3rem;

      .select2-selection__rendered {
        line-height: 3rem;
        padding-left: 0 !important;
      }
      .select2-search--dropdown {
        padding: 0;
      }
    }
  }
  &.select2-container--default.select2-container--open {
    border-bottom: 1px solid $input-focus-color;
    box-shadow: 0 1px 0 0 $input-focus-color;
    .select2-selection__rendered {
      background-color: $select-background;
    }
  }
  .select2-search--dropdown {
    @extend .z-depth-1;
    border: none;
    padding: 0;
    .select2-search__field {
      border: none;
      border-bottom: 1px solid $grey-color;
    }
  }
}

.select2-container {
  @include select2;
}
