@mixin breadcrumbs {
  display: block;
  float: left;
  font-size: $h3-fontsize;
  line-height: $subheader-height;
  white-space: nowrap;
  margin-bottom: 0;
  margin-top: 0;
  max-width: calc(100vw - 40px);
  @media (max-width: $medium-screen) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .breadcrumb {
    color: black;
  }
  &--history {
    li {
      &:before {
        content: url('../images/chevron-right.svg');
        vertical-align: text-bottom;
        display: inline-block;
      }
      &:first-child:before {
        display: none;
      }
    }
  }
  li {
    float: none;
    line-height: $subheader-height;
    color: black;
    display: inline-block;
    @media (max-width: $medium-screen-up) {
      &:first-of-type:not(.visible-s) {
        display: none;
        &:before {
          display: none;
        }
      }
      &:nth-of-type(2) {
        &:before {
          display: none;
        }
      }
      &:before {
        display: none;
      }
      &.back-mobile {
        display: inline-block;
        svg {
          vertical-align: middle;
          margin-right: 10px;
          fill: black;
        }
      }
    }
    @media (min-width: $medium-screen-up) {
      &:first-of-type {
        display: inline-block;
      }
      &.back-mobile {
        display: none;
      }
    }
    &:hover {
      background: none;
    }
    a {
      color: black;
      display: inline-block;
      font-size: $h3-fontsize;
      padding: 0;
      transition: color $transition-duration;

      &:hover {
        text-decoration: underline;
      }
    }
    * {
      display: inline-block;
      overflow: hidden;
    }
  }

  &--ellipsed {
    &-item {
      text-overflow: ellipsis;
      white-space: nowrap;
      color: black;
      max-width: calc(100vw - 80px);
      @media (min-width: $medium-screen-up) {
        max-width: 14em;
      }
    }
  }
}

.breadcrumbs {
  @include breadcrumbs;
}
