@mixin list {
  width: 100%;

  .collection {
    &-item {
      padding: 0;
      a {
        display: block;
        padding: 10px 20px 0px 20px;
      }
      &:hover {
        background: #f2f2f2;
      }
    }
  }

  & &-item.avatar {
    min-height: auto;
  }

  & &-item {
    padding-bottom: 0;
    position: relative;

    &-name {
      display: block;
      overflow: hidden;
      padding-right: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $off-black;

      &--single {
        margin-bottom: 10px;
        color: $off-black;
      }
    }

    &-price,
    &-desc,
    &-name--single {
      margin: 0;
      padding-bottom: 10px;
    }

    &-price {
      color: $off-black;
    }

    &-desc {
      @extend .grey-text;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 30px;
    }

    &-icon {
      font-size: 16px;
      opacity: 0.6;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.list {
  @include list;
}
