.body-orders {
  .order-notes {
    .list-item {
      margin-bottom: 1rem;
      p {
        white-space: pre-wrap;
      }
    }
  }
}
