@mixin product-gallery {
  margin: 0;
  &-item {
    position: relative;

    @media (min-width: 1600px) {
      width: 25% !important;
    }

    &-reorder {
      position: absolute;
    }

    &-image {
      &--ghost {
        background: map_get($grey, lighten-5);
        padding: 64px 0 63px;
        text-align: center;
      }
    }

    &-title,
    &-desc {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--noclip {
        padding: 104px 3rem 103px;
        text-align: center;
        white-space: normal;
      }
    }

    &-text {
      background: rgba(black, 0.5);
      bottom: 0;
      color: white;
      height: 48px;
      line-height: 48px;
      left: 0;
      overflow: hidden;
      padding: 0 calc(#{$gutter-width/2} + 48px) 0 $gutter-width/2;
      position: absolute;
      right: 0;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .sortable__drag-area {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    cursor: move;
  }

  .sortable-ghost {
    opacity: 0.4;
  }
}

.product-gallery {
  @include product-gallery;
}
