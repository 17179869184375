.card .card-action {
  padding: 10px 8px;
  &.wide {
    padding: 16px;
  }
  a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
    @extend .btn-flat;
    margin-right: 0;
    &:hover {
      color: $secondary-color;
    }
  }
}

.pill-btn {
  margin: 2px;
  padding: 4px;
  padding-inline: 8px;
  border-radius: 16px;
  cursor: pointer;
  background-color: bisque;
  vertical-align: middle;
  height: fit-content;
  line-height: initial;
  width: fit-content;
}

.webshare-button {
  &__text {
    display: none;
  }
  &__url {
    display: none;
  }
}

.clipboard-copy {
  span {
    pointer-events: None;
    font-size: 14px;
  }

  input {
    display: none;
  }
}

.btn {
  &:not(.btn-floating) {
    box-shadow: none;
  }
  &-flat {
    @extend .waves-lightgray;
    color: $secondary-color;
    &:hover {
      background: unset;
    }
    &:focus {
      background: rgba(0, 0, 0, 0.05);
    }
  }
  &-fab,
  &-floating {
    svg {
      vertical-align: middle;
    }
    &.btn-fab-hidden {
      transform: scaleX(0) scaleY(0);
    }
  }
  &-fab {
    &-fixed {
      @extend .fixed-action-btn;
      @media (min-width: $medium-screen) {
        position: absolute;
        top: 4px;
        right: 25%;
      }
      @media (min-width: $large-screen) {
        right: calc(25% - 60px);
      }
    }
    &-default {
      @extend .btn-fab;
      @extend .btn-floating;
      @extend .btn-large;
      padding: 0;
      height: 56px;
    }
    &-presentation {
      @extend .btn-fab-default;
      @extend .fixed-action-btn;
      position: relative;
      top: -0.1rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      line-height: 1.8rem;
      padding-top: 0;
      margin: 0 0.2rem;
      pointer-events: none;
      cursor: unset;

      &-card {
        @extend .btn-fab-presentation;
        right: 0;
        margin: 6px;
        top: unset;
        bottom: 0;
        position: absolute !important;
        cursor: pointer;
        opacity: 0.7;
        pointer-events: all;

        &:hover {
          transform: scale(1.05);
          transition: 200ms;
        }
      }
    }
  }
}
