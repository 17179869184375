.chars-left {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__label {
    position: relative !important;
    margin-left: -10px;
  }
}
