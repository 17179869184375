.scan-barcode-container {
  position: relative;

  input {
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
  }
}
