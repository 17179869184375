.timeline-outer {
  padding-left: $timeline-date-width;
  .timeline {
    border-left: $timeline-width $primary-color-light solid;
    position: relative;
    list-style: none;
    .event {
      padding-bottom: 1rem;
      margin-bottom: $timeline-event-margin;
      position: relative;
      &:before,
      &:after {
        position: absolute;
        display: block;
      }
      &:before {
        left: -$timeline-date-width;
        content: attr(data-date);
        text-align: right;
        top: calc(
          #{$timeline-circle-radius} + #{$timeline-width} + 0.5px - #{$label-font-size} +
            0.1rem
        );
        width: $timeline-date-width;
        padding-right: 3rem;
      }
      &:after {
        box-shadow: 0 0 0 $timeline-width $primary-color-light;
        left: -$timeline-circle-radius - ($timeline-width / 2) - 1;
        background: $body-bg;
        border-radius: 50%;
        height: 2 * $timeline-circle-radius + 1;
        width: 2 * $timeline-circle-radius + 1;
        content: '';
        top: $timeline-width;
      }
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
      .content {
        margin-left: 3rem;
        min-height: 1.5rem;
        position: relative;
        top: calc(
          #{$timeline-circle-radius} + #{$timeline-width} + 0.5px - #{$label-font-size} +
            0.1rem
        );
      }
      .status {
        position: absolute;
        left: 0;
        top: 0;
      }
      &:last-of-type {
        height: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .timeline-outer {
    padding-left: 0;
    margin: 0 1rem;
    .timeline {
      .event {
        &:before {
          left: 3rem;
          top: calc(
            #{$timeline-circle-radius} + #{$timeline-width} + 0.5px - #{$label-font-size} +
              0.7rem
          );
          min-width: 0;
          text-align: left;
        }
        .content {
          position: relative;
          top: calc(
            #{$timeline-circle-radius} + #{$timeline-width} + 0.5px - #{$label-font-size} -
              0.5rem
          );
          padding-left: $timeline-date-width;
        }
      }
    }
  }
}

@media (max-width: $small-screen) {
  .timeline-outer {
    .timeline {
      .event {
        margin-bottom: $timeline-event-margin / 2;
        .content {
          padding-left: 0;
          top: calc(
            #{$timeline-circle-radius} + #{$timeline-width} + 0.5px - #{$label-font-size} -
              0.5rem
          );
          padding-top: 3rem;
        }
      }
    }
  }
}
