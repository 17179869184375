@mixin data-table {
  &-container {
    overflow: auto;
    .avatar {
      padding: 0px;
      height: 60px;
      text-align: center;
      img {
        max-height: 60px;
        vertical-align: middle;
      }
    }
  }

  &-action {
    border-top: 1px solid $table-border-color;
    padding: 10px 8px;
  }

  &-header-action {
    border-bottom: 1px solid $table-border-color;
    padding: 14px 8px;
  }

  &-bulk-price-update {
    display: none;
    &.show {
      display: block;
    }
  }

  &-bulk-actions {
    box-shadow: none;
    transition: $transition-duration;
    overflow: hidden;
    display: none;
    position: absolute;
    right: 0;
    z-index: 1;
    padding: 0 24px 0 0;
    @media (max-width: $small-screen) {
      height: 56px;
      overflow: visible;
    }
    a {
      color: $secondary-color;
    }
    &.show {
      background: #fff;
      color: $off-black;
      display: block;
    }
    &__action-choice {
      width: calc(100% - 60px);
      position: absolute;
      right: 8px;
      ul {
        li {
          a {
            margin: 0;
          }
        }
      }
      @media (max-width: $small-screen) {
        display: none;
      }
    }
    &__dropdown-container {
      right: -5px;
      position: relative;
      display: none;
      svg {
        fill: $grey-color;
      }
      a {
        line-height: 70px;
      }
      @media (max-width: $small-screen) {
        display: block;
      }
    }
    &__select-all {
      float: left;
      margin: 11px 8px 0 24px;
      @media (max-width: $small-screen) {
        margin: 6px 8px 0 24px;
      }
    }
    &__selected-items {
      margin-left: -10px;
      line-height: 56px;
    }
  }

  &-header {
    align-items: center;
    display: flex;
    height: 64px;
    a {
      color: $secondary-color;
    }
    .label {
      line-height: 1;
      margin-right: 16px;
    }
    h5 {
      text-transform: initial;
      font-size: 24px;
      font-weight: 300;
    }
  }

  &-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 16px;

    .input {
      padding: 0;
    }
  }

  &-title {
    flex-grow: 1;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    margin: 0;
    padding: 0 16px;
    &--no-data {
      padding: 0 15px;
    }
  }

  &.last-right-align {
    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }

  input[type='checkbox'].filled-in + label {
    margin-top: 5px;
    padding: 0;

    &:after {
      display: inline-block;
      position: static;
    }
  }

  tr {
    &.highlight {
      background-color: #f8f8f8;
    }
    &.last-no-border {
      &:last-of-type {
        border-bottom: none;
      }
    }
    &[data-action-go] > td:not(.ignore-link):hover {
      cursor: pointer;
    }
  }

  .danger {
    color: $error-color;
  }

  th,
  td {
    height: 48px;
    overflow: hidden;
    padding: 0 16px;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    a {
      color: $secondary-color;
      text-decoration: underline;
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }

    &.no-padded {
      padding: 0;
    }

    &.nowrap {
      white-space: normal;
      padding: 10px 24px;
    }

    .dropdown-trigger {
      color: inherit;
      display: inline-block;
      svg {
        vertical-align: bottom;
      }
    }

    &.fulfillment-quantity {
      .fulfillment-quantity-input {
        display: flex;
        align-items: center;

        input {
          width: 64px;
          margin-bottom: 0;
          text-align: right;
        }
      }
      label p {
        margin: 8px 0;
      }
    }
  }

  th {
    font-weight: normal;
    font-size: 12px;
    height: 56px;
    color: $grey-color;
    svg {
      display: none;
    }
    &.active {
      a {
        font-weight: 600;
        color: lighten(#000, 13%);
        padding-right: 16px;
      }
      svg {
        display: inline-block;
        fill: lighten(#000, 13%);
        position: absolute;
        top: -2px;
        right: -4px;
        width: 16px;
        height: 16px;
      }
    }
    a {
      position: relative;
      color: $grey-color;
      &:hover {
        color: darken($grey-color, 15%);
      }
    }
  }

  tfoot:not(.no-bold) {
    font-weight: bold;
  }

  .wide {
    width: 99%;
  }

  &-icon {
    padding: 0 8px !important;
  }
}

.data-table {
  @include data-table;
}
