.search {
  float: none;
  margin-left: 270px;
  padding: 10px 0;
  transition: $transition-duration;
  @media (max-width: $small-screen) {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    background: $primary-color;
    transform: translateX(-100%);
    margin: 0;
    height: 64px;
    z-index: 1000;
    &.expanded {
      transform: translateX(0px);
    }
    .input-field {
      width: calc(100% - 128px);
      display: inline-block;
      vertical-align: top;
      padding: 5px 0;
      input[type='search'] {
        position: relative;
        padding-left: 1rem;
      }
    }
    a {
      display: inline-block;
      width: 60px;
      height: 64px;
      padding: 0;
      text-align: center;
      line-height: 80px;
      position: relative;
      top: -10px;
      vertical-align: top;
      &#btn-search-close {
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background: #fff;
          width: 2px;
          height: 22px;
          top: 22px;
          left: 30px;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .input-field {
    height: 36px;
    input {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      box-sizing: border-box;
      display: inline-block;
      margin-bottom: 0;
      transition: background-color 0.2s ease-out;
      width: 100% !important;
      line-height: 1;
      &::placeholder {
        color: black;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
      &:focus {
        background-color: white;
        border: none;
        box-shadow: none;
        ~ label {
          svg {
            fill: $off-black;
          }
        }
      }
    }
    label {
      transition: color 0.2s ease-out;
      margin-top: 22px;
    }
  }
}
