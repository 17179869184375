// .loader,
// .loader:after {
//   border-radius: 50%;
//   width: 10em;
//   height: 10em;
// }

// .loader {
//   margin: 25% auto;
//   font-size: 10px;
//   position: relative;
//   text-indent: -9999em;
//   border-top: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-right: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-left: 1.1em solid #000000;
//   -webkit-transform: translateZ(0);
//   -ms-transform: translateZ(0);
//   transform: translateZ(0);
//   -webkit-animation: load8 1s infinite linear;
//   animation: load8 1s infinite linear;
// }

// @-webkit-keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// @keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }

//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }

// #loadingDiv {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0.6;
//   background-color: rgb(255, 255, 255);
// }

.loaderContainer {
  height: 36px;
  width: 48px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  z-index: 1;

  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
