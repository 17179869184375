@mixin form {
  .select-wrapper {
    &.invalid {
      input {
        @extend .invalid;
      }
    }
  }

  label {
    @extend .active;
    transition: none;
  }

  input[type='radio'] {
    @extend .with-gap;
  }

  select[multiple] {
    display: initial;
    width: 100%;
  }

  textarea {
    width: 100%;
  }

  p {
    &.help-block {
      margin: -7px 0 15px;
      font-size: $label-font-size;
      clear: both;
    }
    &.margin {
      margin-bottom: 25px;
    }
  }

  .input-field {
    margin-top: 0;
    padding-top: 20px;
    label,
    label.active {
      font-size: 12px;
      top: 0;
      transform: none;
    }
  }

  %input-lg {
    font-size: 2.2rem;
    transition: none;
  }

  &#form-product {
    #id_name {
      @extend %input-lg;
    }

    #id_price {
      @extend %input-lg;
    }
  }

  &#form-variant {
    #id_sku {
      @extend %input-lg;
    }
  }

  &#form-attributes {
    #id_display {
      @extend %input-lg;
    }
  }

  &#form-category {
    #id_name {
      @extend %input-lg;
    }
  }

  &#form-sales {
    #id_name {
      @extend %input-lg;
    }
  }

  &#form-vouchers {
    #id_code {
      @extend %input-lg;
    }
  }

  .sizedimage-mod {
    margin-bottom: $gutter-width/2;

    & ~ label {
      display: none;
    }
  }

  .file-field {
    label {
      display: none;
    }
  }

  .btn--inline {
    display: inline-block;
    margin-top: 1.4rem;
  }

  .datepicker {
    cursor: pointer;
  }

  .checkbox-helptext {
    padding-left: 66px;
  }
}

.switch {
  padding-bottom: 20px;
  position: relative;
  .label {
    position: absolute;
    top: 3px;
  }
}

.input-with-suffix {
  display: table-row;
  input {
    display: table-cell;
  }
  .input-text-suffix {
    display: table-cell;
    @extend .grey-text;
    line-height: 45px;
    padding-left: 10px;
    text-align: right;
  }
  @media (max-width: $small-screen) {
    input {
      width: 80%;
    }
    .input-text-suffix {
      width: 20%;
    }
  }
}

.input-range {
  .separator {
    display: inline-block;
    margin-top: 10px;
    width: 1rem;
  }
  &--field {
    display: inline-table;
    width: calc(50% - 0.8rem);
    @media (max-width: $medium-screen) {
      .input-text-suffix {
        text-align: center;
      }
    }
  }
}

.hidden {
  display: none;
}

.phone-prefix-input {
  display: grid;
  grid-template-columns: 8rem calc(100% - 8rem);
  align-items: stretch;
  &__field {
    &:last-of-type {
      input[type='text'] {
        margin-left: 0.5rem !important;
      }
    }
  }
}

.medium-editor-element {
  padding-bottom: 1rem;
  border-bottom: 1px $grey-color solid;
  outline: none;
  transition: all $transition-duration;
  &:focus,
  &:active {
    border-bottom-color: $secondary-color;
    box-shadow: 0 1px 0 0 $secondary-color;
  }
}

label.svg-submit-btn input[type='submit'] {
  display: none;
}

.main form,
.modal form {
  @include form;
}

.is_featured_checkbox {
  .input.input-field {
    padding-top: 0 !important;
    padding-bottom: 10px;
  }
}
