.product-header {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;

  &__title {
  }
  &__switch {
  }

  .status-card {
    @extend .m-padding;
    @extend .card;
    min-width: 14.25rem;
    padding-inline: 2rem;
  }
}

.product-description {
  img {
    max-width: 100%;
  }
}
