.collapse {
  max-height: 1000px;
  transition: $transition-duration;
  &.collapsed {
    max-height: 0;
    overflow: hidden;
  }
}

.collapse-activate {
  position: absolute;
  top: 21px;
  right: 20px;
  svg {
    width: 24px;
    height: 24px;
    fill: $grey-color;
  }
}
