.medium {
  &-toolbar {
    &-arrow {
      &-under:after {
        border-color: $medium-editor-bgcolor transparent transparent transparent;
        top: $medium-editor-button-size;
        z-index: -1;
      }
      &-over:before {
        border-color: transparent transparent $medium-editor-bgcolor transparent;
      }
    }
  }

  &-editor {
    &-anchor-preview {
      a {
        color: $medium-editor-link-color;
        text-decoration: none;
      }
    }

    &-element {
      outline: none;
      border-bottom: 1px $grey-color solid;
      transition: all $transition-duration;
      padding-bottom: 1rem;
      &:focus,
      &:active {
        border-bottom-color: $secondary-color;
        box-shadow: 0 1px 0 0 $secondary-color;
      }
    }

    &-toolbar {
      background-color: $medium-editor-bgcolor;
      border: none;
      border-radius: $medium-editor-border-radius;

      li {
        button {
          background-color: transparent;
          border: none;
          box-sizing: border-box;
          color: $medium-editor-link-color;
          height: $medium-editor-button-size;
          min-width: $medium-editor-button-size;
          padding: 5px 12px;
          transition:
            background-color 0.2s ease-in,
            color 0.2s ease-in;
          &:hover {
            background-color: $medium-editor-hover-color;
            color: $medium-editor-button-active-text-color;
          }
        }
      }

      &-close {
        margin-right: 16px;
      }

      &-form {
        background: $medium-editor-bgcolor;
        border-radius: $medium-editor-border-radius;
        color: $medium-editor-link-color;
        overflow: hidden;
        a {
          color: $medium-editor-link-color;
          transform: translateY(2px);
          float: right;
        }
      }

      &-input {
        background: $medium-editor-bgcolor;
        box-sizing: border-box;
        color: $medium-editor-link-color;
        height: $medium-editor-button-size;
        width: 220px;
        border-bottom: 1px $grey-color solid !important;
        margin-bottom: 0.5rem !important;
        padding: 0 1rem !important;
        &:active,
        &:focus {
          border-bottom-color: $secondary-color !important;
          box-shadow: 0 0 0 1px $secondary-color;
        }

        &::placeholder {
          color: rgba($medium-editor-placeholder-color, 0.8);
        }
      }

      &-actions {
        li,
        button {
          border-radius: $medium-editor-border-radius;
        }
      }

      &-anchor-preview {
        background: $medium-editor-bgcolor;
        border-radius: $medium-editor-border-radius;
        padding: 5px 12px;
      }
    }

    &-button {
      &-first {
        border-bottom-left-radius: $medium-editor-border-radius;
        border-top-left-radius: $medium-editor-border-radius;
        padding-left: 24px;
      }

      &-last {
        border-bottom-right-radius: $medium-editor-border-radius;
        border-right: none;
        border-top-right-radius: $medium-editor-border-radius;
        padding-right: 24px;
      }

      &-active {
        background-color: $medium-editor-hover-color;
        color: $medium-editor-button-active-text-color;
      }
    }
  }
}

.rich-text-editor {
  // add bottom margin to make the field's help-text not clashing with the
  // bottom border
  margin-bottom: 1rem;
}
