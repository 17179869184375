html {
  background: $body-bg;
  @media only screen and (min-width: 0) {
    font-size: 14px;
  }
  @media only screen and (min-width: $large-screen) {
    font-size: 13px;
  }
}

strong {
  font-weight: bold;
}

h5 {
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding-top: 10px;
}

h6 {
  @extend .grey-text;
  font-size: 13px;
}

.no-margin {
  margin: 0;
}

.switch-two-columns {
  @media (min-width: $large-screen) {
    float: left !important;

    &:first-child {
      float: right !important;
    }
  }
}

.text-lg {
  font-size: 120%;
}

.text-padding {
  padding: 5px 0;
}

.label {
  @extend .grey-text;
  font-size: 12px;
  text-transform: uppercase;

  &.label-danger {
    @extend .red-text;
  }

  &.label-success {
    @extend .green-text;
  }

  &.label-lite_success {
    @extend .light-green-text;
  }

  &.label-primary {
    @extend .blue-text;
  }
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  vertical-align: middle;
  word-wrap: normal;
  white-space: nowrap;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.formatted-address {
  font-style: normal;
}

.readonly-msg {
  border: 1px solid #ee6e73;
  box-shadow: none;
  color: $primary-color;
}

.currency {
  @extend .grey-text;
  font-size: 12px;
  font-weight: normal;
}

.help-box {
  svg {
    vertical-align: middle;
  }
}

.not-found {
  text-align: center;
  p {
    font-size: 1.7rem;
    text-transform: uppercase;
  }
}

.medium-img {
  width: 120px;
  height: 120px;
  text-align: center;

  img {
    max-height: 120px !important;
    width: auto !important;
  }
}

li#version-indicator {
  p {
    @extend .grey-text, .text-lighten-1;
  }
}

.main {
  form {
    .input-field {
      label {
        height: auto;
        pointer-events: auto;

        &:not(.label-icon) {
          transform: none;
        }
      }
    }
  }
}

.address {
  margin-bottom: 1rem;
  .phone-number {
    margin-left: 0.5rem;
    color: unset;
  }
}

svg {
  &.fill {
    &-primary {
      fill: $primary-color;
    }
    &-secondary {
      fill: $secondary-color;
    }
  }
}

.chips-container {
  margin-top: 18px;
  a {
    color: #999;
  }
}

.waves-effect.waves-lightgray .waves-ripple {
  background-color: rgba(0, 0, 0, 0.05);
}

.card {
  .card-title {
    overflow-wrap: break-word;
  }

  .card-border {
    border-bottom: 1px solid $table-border-color;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.row {
  .col {
    // Prevent from horizontal scroll on mobile devices
    max-width: 100%;
  }
}

.m-padding {
  padding: 1rem;
}

.no-left-padding {
  padding-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.primary-btn {
  color: white !important;
  background: $secondary-color !important;
}

.error {
  color: #f04242 !important;
}
