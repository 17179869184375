.zero-page {
  padding: 3rem;
  &__title {
    font-weight: 400;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  &__content {
    font-size: 1.2rem;
    color: $grey-color;
  }
}
