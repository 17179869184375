@mixin tabs {
  background: none;

  .indicator {
    height: 3px;
  }

  li {
    a {
      color: #000 !important;
      span {
        display: none;
        @media (min-width: $small-screen-up) {
          display: inline;
        }
      }
      svg {
        @media (min-width: $small-screen-up) {
          display: none;
        }
      }
    }
  }
}

.tabs {
  @include tabs;
}
