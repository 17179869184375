.print-show {
  display: none;
}

@media print {
  .print-show {
    display: block !important;
  }
  .print-hide {
    display: none !important;
  }
  .p6 {
    width: 50% !important;
  }
  html {
    background-color: white;
  }
  .main {
    .container {
      margin-top: 0;
    }
  }
  .sidenav {
    display: none;
  }
  nav {
    a:not(.logo),
    form {
      display: none;
    }
    .logo path {
      fill: $off-black;
    }
  }
  .subheader {
    color: #000;
    li:first-of-type {
      display: inline-block !important;
    }
    svg {
      display: none;
    }
  }
  .card {
    box-shadow: none;
    padding: 0;
  }
  .data-table-action {
    display: none;
  }
  .data-table th {
    color: $off-black;
  }
  .btn,
  .dropdown-trigger svg {
    display: none;
  }
  .tabs {
    display: none;
  }
  .print-tabs {
    text-transform: uppercase;
    margin: 14px 24px;
    font-size: 14px;
    text-decoration: underline;
  }
  .tab-content {
    display: block !important;
  }
  .shipment-table,
  #payment-history,
  #order-history {
    page-break-inside: avoid;
  }
}
